// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---packages-blog-core-src-templates-collection-author-js": () => import("./../../../packages/blog/core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-core-src-templates-collection-category-js": () => import("./../../../packages/blog/core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-core-src-templates-collection-tag-js": () => import("./../../../packages/blog/core/src/templates/collection.tag.js" /* webpackChunkName: "component---packages-blog-core-src-templates-collection-tag-js" */),
  "component---packages-blog-core-src-templates-post-js": () => import("./../../../packages/blog/core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-core-src-templates-post-js" */),
  "component---packages-blog-core-src-templates-posts-js": () => import("./../../../packages/blog/core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-core-src-templates-posts-js" */),
  "component---packages-blog-pages-src-pages-404-jsx": () => import("./../../../packages/blog/pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-404-jsx" */),
  "component---packages-blog-pages-src-pages-kontakt-jsx": () => import("./../../../packages/blog/pages/src/pages/kontakt.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-kontakt-jsx" */),
  "component---packages-blog-pages-src-pages-o-nas-jsx": () => import("./../../../packages/blog/pages/src/pages/o-nas.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-o-nas-jsx" */),
  "component---packages-blog-pages-src-pages-odpowiedzialnosc-jsx": () => import("./../../../packages/blog/pages/src/pages/odpowiedzialnosc.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-odpowiedzialnosc-jsx" */),
  "component---packages-blog-pages-src-pages-polityka-prywatnosci-jsx": () => import("./../../../packages/blog/pages/src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-polityka-prywatnosci-jsx" */)
}

