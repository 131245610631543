import React from 'react'
import { Global } from '@emotion/core'
import { Flex, Box } from 'theme-ui'
import pageContextProvider from '@helpers/pageContextProvider'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
import CookieConsent from 'react-cookie-consent';



const Layout = ({ children, pageContext, location }) => (
  <pageContextProvider.Provider value={{ pageContext, location }}>
    <Flex variant='layout.layout'>
      <Global styles={theme => theme.global(theme)} />
      <Header />
      <Box variant='layout.body'>{children}</Box>
      <Footer />
    </Flex>
    <CookieConsent
          location="bottom"
          buttonText="Akceptuję"
          declineButtonText="Nie akceptuję"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ background: "#FFFFFF", color: "#718096", fontSize: "13px" }}
          buttonStyle={{ background: "#0050c7", color: "#FFFFFF", fontSize: "13px", borderRadius: "5px" }}>
          Blog wykorzystuje pliki cookies w celu prawidłowego jego działania, korzystania z narzędzi analitycznych, wtyczek społecznościowych, systemu komentarzy oraz odtwarzacza wideo, zgodnie z Polityką Prywatności. 
          Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.
    </CookieConsent>
  </pageContextProvider.Provider>
)

export default Layout
